import bsCardBG from '../../assets/images/gameCards/home_thumb_bs.png';
import uwoCardBG from '../../assets/images/gameCards/home_thumb_UWO.png';
import wrCardBG from '../../assets/images/gameCards/home_thumb_WR_.png';
import wozCardBG from '../../assets/images/gameCards/home_thumb_WOZ_.png';
import bsmBG1 from '../../assets/images/gameCards/home_thumb_BSM_.png';
import ltBG from '../../assets/images/gameCards/home_thumb_LT_.png';
import khBG from '../../assets/images/gameCards/home_thumb_KH_.png';
import pubBSCardBG from '../../assets/images/gameCards/home_thumb_bsr.png';
import fsCardBG from '../../assets/images/gameCards/home_thumb_4s.png';
import ndCardBG from '../../assets/images/gameCards/home_thumb_9D.png';
import eosBG from '../../assets/images/gameCards/home_thumb_eos.png';
import tosBG from '../../assets/images/gameCards/home_thumb_tos.png';
import sf2BG from '../../assets/images/gameCards/home_thumb_sf2.png';

// game tile
import BSM_thumb_img from '../../assets/images/games_thumb/thumb_bs4.png';
import BS_thumb_img from '../../assets/images/games_thumb/thumb_2_bs2.png';
import LT_thumb_img from '../../assets/images/games_thumb/thumb_2_lt.png';
import WOZ_thumb_img from '../../assets/images/games_thumb/thumb_2_woz.png';
import KWHO_thumb_img from '../../assets/images/games_thumb/thumb_2_kwho.png';
import UWO_thumb_img from '../../assets/images/games_thumb/thumb_2_uwo.png';
import WR_thumb_img from '../../assets/images/games_thumb/thumb_2_wr.png';
import FS_thumb_img from '../../assets/images/games_thumb/thumb_2_fs.png';
import ND_thumb_img from '../../assets/images/games_thumb/thumb_9D.png';
import EOS_thumb_img from '../../assets/images/games_thumb/thumb_eos.png';
import TOS_thumb_img from '../../assets/images/games_thumb/thumb_tos.png';
import SF2_thumb_img from '../../assets/images/games_thumb/thumb_sf2.png';

import BSM_modal_img from '../../assets/images/modal_games/popup_bsm.png';
import BS_modal_img from '../../assets/images/modal_games/popup_bsr.png';
import KWHO_modal_img from '../../assets/images/modal_games/popup_kwho.png';
import LT_modal_img from '../../assets/images/modal_games/popup_lt.png';
import UWO_modal_img from '../../assets/images/modal_games/popup_UWO.png';
import WOZ_modal_img from '../../assets/images/modal_games/popup_woz.png';
import WR_modal_img from '../../assets/images/modal_games/popup_wr.png';
import FS_modal_img from '../../assets/images/modal_games/popup_4s.png';
import ND_modal_img from '../../assets/images/modal_games/popup_9D.png';
import EOS_modal_img from '../../assets/images/modal_games/popup_eos.png';
import TOS_modal_img from '../../assets/images/modal_games/popup_tos.png';
import SF2_modal_img from '../../assets/images/modal_games/popup_sf2.png';

const INITIAL_STATE = {
    gameItems: [
        // blackshot mobile
        {
            newTag: false,
            env: ['Android', 'iOS'],
            category: ['develop'],
            id: 7,
            commingSoon: false,
            bgImage: bsmBG1,
            bgImage2: BSM_thumb_img,
            modalImage: BSM_modal_img,
        },
        // blackshot
        {
            newTag: false,
            env: ['Windows'],
            category: ['publish', 'develop'],
            id: 0,
            commingSoon: false,
            bgImage: bsCardBG,
            bgImage2: BS_thumb_img,
            modalImage: BS_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/blackshotsea',
                },
                {
                    name: 'facebookGroup',
                    link: 'https://www.facebook.com/groups/blackshotseacommunity',
                },
                {
                    name: 'youtube',
                    link: 'https://www.youtube.com/c/BlackShotSEAOfficial',
                },
                {
                    name: 'discord',
                    link: 'https://discord.com/invite/blackshot',
                },
            ],
        },
        // war of zombie
        {
            newTag: false,
            env: ['Windows'],
            category: ['develop'],
            id: 8,
            commingSoon: false,
            bgImage: wozCardBG,
            bgImage2: WOZ_thumb_img,
            modalImage: WOZ_modal_img,
        },
        // kwonho
        {
            newTag: false,
            env: ['Windows'],
            category: ['develop'],
            id: 9,
            commingSoon: false,
            bgImage: khBG,
            bgImage2: KWHO_thumb_img,
            modalImage: KWHO_modal_img,
        },

        // sf2 (test Joonyoung)
        {
            newTag: true,
            env: ['Windows'],
            category: ['publish'],
            id: 1,
            commingSoon: false,
            bgImage: sf2BG,
            bgImage2: SF2_thumb_img,
            modalImage: SF2_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/SpecialForce2Official',
                },
                {
                    name: 'youtube',
                    link: 'https://www.youtube.com/@SKILL-SpecialForce2',
                },
                {
                    name: 'twitch',
                    link: 'https://www.twitch.tv/specialforce2official',
                },
            ],
        },

        // tos (test Joonyoung)
        {
            newTag: true,
            env: ['Windows'],
            category: ['publish'],
            id: 2,
            commingSoon: false,
            bgImage: tosBG,
            bgImage2: TOS_thumb_img,
            modalImage: TOS_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/treeofsaviorglobal/',
                },
                {
                    name: 'discord',
                    link: 'https://discord.com/channels/1066084043034218576/1066084043696906292/1080583493312057487',
                },
                {
                    name: 'instagram',
                    link: 'https://www.instagram.com/treeofsaviorglobal/',
                },
                {
                    name: 'twitter',
                    link: 'https://twitter.com/Papaya_TOS',
                },
            ],
        },
        // eos
        {
            newTag: false,
            env: ['Windows'],
            category: ['publish'],
            id: 3,
            commingSoon: false,
            bgImage: eosBG,
            bgImage2: EOS_thumb_img,
            modalImage: EOS_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/EchoOfSoulPapayaPlay',
                },
                {
                    name: 'youtube',
                    link: 'https://www.youtube.com/channel/UC1AgsKjJpSrEf-hZv32M0Yw',
                },
                {
                    name: 'discord',
                    link: 'https://discord.com/invite/echoofsoul',
                },
            ],
        },
        // uwo
        {
            newTag: false,
            env: ['Windows'],
            category: ['publish'],
            id: 4,
            commingSoon: false,
            bgImage: uwoCardBG,
            bgImage2: UWO_thumb_img,
            modalImage: UWO_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/OfficialUnchartedWaters',
                },
                {
                    name: 'twitter',
                    link: 'https://twitter.com/UWO_News',
                },
            ],
        },
        // warrock
        {
            newTag: false,
            env: ['Windows'],
            category: ['publish'],
            id: 5,
            commingSoon: false,
            bgImage: wrCardBG,
            bgImage2: WR_thumb_img,
            modalImage: WR_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/WarRockOfficial/',
                },
                {
                    link: 'https://www.youtube.com/channel/UC1zSXJdzlvINvX5gY2Pw9MQ',
                },
                {
                    name: 'twitter',
                    link: 'https://twitter.com/WarRockOfficial',
                },
                {
                    name: 'discord',
                    link: 'https://discordapp.com/invite/BNNSF6y',
                },
            ],
        },
        // latale
        {
            newTag: false,
            env: ['Windows'],
            category: ['publish'],
            id: 6,
            commingSoon: false,
            bgImage: ltBG,
            bgImage2: LT_thumb_img,
            modalImage: LT_modal_img,
            sns: [
                {
                    name: 'facebook',
                    link: 'https://www.facebook.com/latalena/',
                },
                {
                    name: 'youtube',
                    link: 'https://www.youtube.com/user/lataletube',
                },
                {
                    name: 'twitter',
                    link: 'https://www.twitter.com/papaya_latale',
                },
                {
                    name: 'discord',
                    link: 'https://discord.com/invite/latalena',
                },
            ],
        },
    ],
};

const gamesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default gamesReducer;
